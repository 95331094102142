import ReactDOM from 'react-dom/client';
import App from './components/App';
import 'mapbox-gl/dist/mapbox-gl.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

